import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import Header from '../Header/Header'
import TwoButtons from './TwoButtons';
import ThreeButtons from './TwoButtons';
import FourButtons from './FourButtons';
import DeadPage from '../../pages/DeadPage/DeadPage';

const buttonsPage = ({ navigationObject }) => {
  let page;

  // TODO: we should not be going by key amounts.
  switch((Object.keys(navigationObject).length / 2) - 1) {
    case 2:
      page = <TwoButtons navigationObject={navigationObject} />;
      break;
    case 3:
      page = <ThreeButtons navigationObject={navigationObject} />
      break;
    case 4:
      page = <FourButtons navigationObject={navigationObject} />
      break;
    default:
      return <DeadPage />
  }

  return (
    <div className="ButtonPage">
      <Header />
      <div className="ButtonPage-Header">
        <div className="ButtonPage-Header-Left">
        
        </div>
        <div className="ButtonPage-Header-Center">
          {navigationObject.title.toUpperCase()}
        </div> 
        <div className="ButtonPage-Header-Right">
          { navigationObject.prevPage && 
            <Link to={navigationObject.prevPage}>
              <Button variant="contained" className="ButtonPage-Header-Right-Button">
                Back
              </Button>
            </Link>
          }
        </div>
      </div>
      {page}
    </div>
  )
};

export default buttonsPage;