import React, { Component } from 'react';
import './App.scss';

import WithAuthentication from './HOC/withAuthentication';

const INITIAL_STATE = {
  mess: 'Hello World!',
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  render() {
    return (
      <div className="App">
        <WithAuthentication />
      </div>
    );
  }
}

export default App;
