import React from 'react';
import { Button } from '@material-ui/core';
import { Edit, Delete, Add, Remove } from '@material-ui/icons';
import { EDIT, DELETE, CHECKIN, CHECKOUT } from '../../../constants/constants.table'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const buttonTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#FFA9E7'
    },
  },
})

const TableActions = ({handleClick, dataSource, d, isAdmin}) => {
  const deleteable = d.currentQuantity === 0 || d.currentQuantity === undefined
  switch(dataSource) {
    case 'capitalEquipment': 
      return isAdmin 
        ? <div className='TablePage-Table-Actions'>
          <MuiThemeProvider theme={buttonTheme}>
              <Button onClick={() => handleClick(EDIT, d)} > 
                <Edit color='primary' />
              </Button>
              <Button onClick={() => handleClick(DELETE, d)} disabled={!deleteable} >
                <Delete color={deleteable ? 'error' : 'action'} />
              </Button>
          </MuiThemeProvider>
        </div>
        : null
    case 'consumables':
      return <div className='TablePage-Table-Actions'>
        <MuiThemeProvider theme={buttonTheme}>
          <Button onClick={() => handleClick(CHECKIN, d)}>
            <Add color='primary' />
          </Button>
          <Button onClick={() => handleClick(CHECKOUT, d)} disabled={deleteable}>
            <Remove color={deleteable ? 'action' : 'primary'}/>
          </Button>
          {
            isAdmin && <Button onClick={() => handleClick(EDIT, d)} >
              <Edit color='primary' />
            </Button>
          }
          {
            isAdmin &&
            <Button onClick={() => handleClick(DELETE, d)} disabled={!deleteable} > 
              <Delete color={deleteable ? 'error' : 'action'} />
            </Button>
          }
        </MuiThemeProvider>
      </div>
    default:
      return null;
  }
}

export default TableActions;