import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import auth from '../scripts/auth';
import LoginPage from '../pages/Login/Login';
import Routes from '../components/Routes/Routes'

const INITIAL_STATE = {
  authenticated: false,
  claims: {},
}

class WithAuthentication extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  };

  componentWillMount(){
    setTimeout(async () => {
      if (auth.auth().currentUser) {
        const token = await auth.auth().currentUser.getIdTokenResult()
        const claims = token.claims || {};
        this.setState({ authenticated: true, claims })
      }
    }, 1000)
  }

  render() {
    const { authenticated, claims } = this.state;

    return <BrowserRouter>
      {!authenticated 
        ? <LoginPage
          handleLogin={(authenticated, claims) => this.setState({ authenticated, claims })}
        />
        : <Switch>
          <Route exact path="/" render={(routeProps) => <Routes {...routeProps} claims={claims} />}  />
          <Route path="/:route" render={(routeProps) => <Routes {...routeProps} claims={claims} />} />
        </Switch>
      }
    </BrowserRouter>
  };
};

export default WithAuthentication;
