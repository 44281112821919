import fire from '../../../scripts/firebase';
import { ADD, EDIT, DELETE, CHECKIN, CHECKOUT } from '../../../constants/constants.table'

export const handleFirebaseSubmit = ( state, props, meta ) => {
  const modified = new Date().getTime();
  const { itemID, previousQuantity, deltaQuantity, itemUnitValue, itemTotalValue } = state;
  const { dataSource } = props;

  switch (props.action) {
    // ADD
    case ADD:
      const addObj = {
        ...state,
        itemTotalValue: 0,
        modified,
      };
      objCleanUp(addObj);
      const newItemID = fire.database().ref(dataSource).push().key;
      submitLog(state, props, {newItemID, modified}, meta);
      fire.database().ref(`${dataSource}/${newItemID}`).set(addObj)
        .then(() => {
          props.close();
          props.retrieve();
        });
      break;

    // EDIT
    case EDIT:
      const editObj = {
        ...state,
        modified,
      };
      objCleanUp(editObj);
      submitLog(state, props, {modified}, meta);
      fire.database().ref(`${dataSource}/${itemID}`).set(editObj)
        .then(() => {
          props.close();
          props.retrieve();
        });
      break;

    // DELETE
    case DELETE:
      submitLog(state, props, {modified}, meta)
      fire.database().ref(`${dataSource}/${itemID}`).remove()
        .then(() => {
          props.close();
          props.retrieve();
        });
      break;

    // CHECKIN
    case CHECKIN:
      const checkInCurrentQuantity = Number(previousQuantity) + Number(deltaQuantity);
      const checkInItemTotalValue = Math.round((itemTotalValue + (deltaQuantity * itemUnitValue)) * 100) / 100;
      const checkInObj = {
        ...state,
        modified,
        currentQuantity: checkInCurrentQuantity,
        itemTotalValue: checkInItemTotalValue
      };
      objCleanUp(checkInObj);
      submitLog(state, props, {checkInCurrentQuantity, checkInItemTotalValue, modified}, meta);
      fire.database().ref(`${dataSource}/${itemID}`).set(checkInObj)
        .then(() => {
          props.close();
          props.retrieve();
        });
      break;

    //CHECKOUT
    case CHECKOUT:
      const checkOutCurrentQuantity = Number(previousQuantity) - Number(deltaQuantity);
      const checkOutItemTotalValue = Math.round((itemTotalValue - ((itemTotalValue / previousQuantity) * deltaQuantity)) * 100) / 100;
      const checkOutObj = {
        ...state,
        modified,
        currentQuantity: checkOutCurrentQuantity,
        itemTotalValue: checkOutItemTotalValue
      };
      objCleanUp(checkOutObj);
      submitLog(state, props, {checkOutCurrentQuantity, checkOutItemTotalValue, modified}, meta);
      fire.database().ref(`${dataSource}/${itemID}`).set(checkOutObj)
        .then(() => {
          props.close();
          props.retrieve();
        });
      break;
    default:
      console.error('Something went wrong, check the dialog action at the point of submit.');
      break;
  }
}

const submitLog = (
  { itemID, previousQuantity, deltaQuantity, itemUnitValue = null, itemTotalValue, itemName, attribute, eventTime, leadTime = 0, notes}, // state
  { action, user, dataSource, }, // props
  { newItemID, checkOutCurrentQuantity, checkInCurrentQuantity, checkInItemTotalValue, checkOutItemTotalValue, modified }, //calc
  { useEventTime } //meta
) => {
  const logObj = {
    modified,
    action,
    itemID: newItemID || itemID,
    itemName,
    attribute: attribute || null,
    previousQuantity: previousQuantity || 0,
    deltaQuantity: deltaQuantity || 0, 
    currentQuantity: checkOutCurrentQuantity || checkInCurrentQuantity || 0,
    user, 
    notes,
    itemUnitValue,
    leadTime,
    itemTotalValue: checkInItemTotalValue || checkOutItemTotalValue || itemTotalValue  || 0,
    eventTime: useEventTime ? eventTime.valueOf() : modified,
  };
  fire.database().ref(`${dataSource}Logs`).push(logObj);
}

const objCleanUp = obj => {
  // delete obj.notes; // why was this deleted?
  delete obj.itemID;
  // delete obj.itemUnitValue; // why was this deleted?
  delete obj.previousQuantity;
  delete obj.deltaQuantity;
  delete obj.actions;
  delete obj.eventTime;
}

export default handleFirebaseSubmit