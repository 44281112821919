import { ADD, EDIT, DELETE, CHECKIN, CHECKOUT } from '../constants.table';

const attributes = ['FDM', 'SLA', 'HRDW', 'PCKG', 'GNRL'];

const CAPEQUIPSCHEMA = action => {

  // let checkInOutLabel = undefined;
  // if (action === CHECKIN || action === CHECKOUT) {
  //   checkInOutLabel = action === CHECKIN ? 'Check In' : 'Check Out';
  // }

  return [{
    field: 'TextField',
    label: 'Item Name',
    id: 'itemName',
    disabled: [DELETE, CHECKIN, CHECKOUT],
    required: true,
  },
  {
    field: 'TextField',
    label: 'Attribute',
    id: 'attribute',
    disabled: [DELETE, CHECKIN, CHECKOUT],
    select: true,
    options: attributes,
  },
  {
    field: 'TextField',
    label: 'Quantity',
    id: 'currentQuantity',
    type: 'number',
    disabled: [ADD, DELETE, CHECKIN, CHECKOUT],
  },
  // {
  //   field: checkInOutLabel ? 'TextField' : null,
  //   label: `${checkInOutLabel} Quantity`,
  //   id: 'deltaQuantity',
  //   type: 'number',
  //   disabled: [ADD, EDIT, DELETE],
  //   validationRequired: true,
  // },
  // {
  //   field: action === CHECKIN ? 'TextField' : null,
  //   label: 'Item Unit Value',
  //   id: 'itemUnitValue',
  //   type: 'number',
  //   startAdornment: '$',
  //   disabled: [],
  // },
  {
    field: action === EDIT ? 'TextField' : null,
    label: 'Item Total Value',
    id: 'itemTotalValue',
    type: 'number', 
    startAdornment: '$',
    disabled: [],
  },
  {
    field: 'TextField',
    label: 'Lead Time',
    id: 'leadTime',
    type: 'number',
    endAdornment: 'day(s)',
    disabled: [DELETE, CHECKIN, CHECKOUT],
    validationRequired: true,
  },
  {
    field: 'TextField',
    label: 'Notes',
    id: 'notes',
    disabled: [],
  },
  {
    field: 'submitButton',
    id: 'submitButton'
  }];
} 


export default CAPEQUIPSCHEMA;