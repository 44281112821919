import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import './DeadPage.scss';

const DeadPage = (props) => {
  return (
    <div className="DeadPage">
      <br/>
      Something went wrong. Please go back, or click below to go home. 
      <br/> 
      <br/>
      <Link to='/'>
        <Button variant="contained" className="ButtonPage-Button">
          Home
        </Button>
      </Link>
    </div>
  )
}

export default DeadPage;