import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import './Routes.scss';

const fourButtons = (props) => {
  const { navigationObject: obj } = props;
  return (
    <div className="ButtonPage-Four">
      <Link to={obj.Link1}>
        <Button variant="contained" className="ButtonPage-Button">
          {obj.Text1}
        </Button>
      </Link>
      <Link to={obj.Link2}>
        <Button variant="contained" className="ButtonPage-Button">
          {obj.Text2}
        </Button>
      </Link>
      <Link to={obj.Link3}>
        <Button variant="contained" className="ButtonPage-Button">
          {obj.Text3}
        </Button>
      </Link>
      <Link to={obj.Link4}>
        <Button variant="contained" className="ButtonPage-Button">
          {obj.Text4}
        </Button>
      </Link>
    </div>
  )
}

fourButtons.propTypes = {
  navigationObject: PropTypes.shape().isRequired,
};

export default fourButtons;