import React from 'react';
import { Dialog, TextField, Button, MenuItem, InputAdornment } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import Moment from 'moment';
import CAPEQUIPMENTSCHEMA from '../../../constants/tableDialogRows/capitalEquipment';
import CONSUMABLESSCHEMA from '../../../constants/tableDialogRows/consumables';
import { ADD, EDIT, CHECKIN, CHECKOUT, CAPITALEQUIPMENT, CONSUMABLES } from '../../../constants/constants.table'
import handleFirebaseSubmit from './TableDialog.services'
import './TableDialog.scss';

const INITIAL_STATE = {
  fields: {},
  invalidFields: {},
  meta: {
    useEventTime: false,
  },
};

class TableDialog extends React.Component {
  /**
   * Initializers
   */
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentWillMount() {
    let fields = {};
    switch(this.props.action) {
      case ADD:
        for (let key of this.props.topRow) {
          fields[key] = '';
        }
        fields.currentQuantity = 0;
        fields.itemTotalValue = 0;
        fields.notes = '';
        fields.leadTime = '';
        this.setState({
          ...INITIAL_STATE,
          fields,
        });
        break;
        /** 
        case EDIT:
          this.setState({
            ...this.props.selected,
            previousQuantity: this.props.selected.currentQuantity,
          });
          break;
        case DELETE:
          this.setState({
            ...this.props.selected,
          });
          break;
        case CHECKIN:
          this.setState({
            ...this.props.selected,
            previousQuantity: this.props.selected.currentQuantity,
            itemUnitValue: 0,
            deltaQuantity: 0,
          });
          break;
        case CHECKOUT:
          this.setState({
            ...this.props.selected,
            previousQuantity: this.props.selected.currentQuantity,
            deltaQuantity: 0,
          });
          break;
        */
      default:
        fields = {...this.props.selected}
        fields.previousQuantity = this.props.selected.currentQuantity;
        fields.itemUnitValue = '';
        fields.deltaQuantity = '';
        fields.notes = '';
        fields.eventTime = new Moment();
        this.setState({
          ...INITIAL_STATE,
          fields,
        });
        break;
    }
  }

  /**
   * Handlers
   */

  _handleChange = validationRequired => e => {
    // e.target.name is for select drop downs
    const key = e.target.id || e.target.name;
    if (validationRequired) {
      this._handleValidateField(key, e.target.value, this.props.action)
    } else {
      const value = e.target.value !== '' && e.target.type === 'number'
        ? Number(e.target.value)
        : e.target.value;
      this.setState({ 
        ...this.state,
        fields: {
          ...this.state.fields,
          [key]: value 
        }
      });
    }
  }

  _handleMomentChange = moment => {
    // comes in as a momentjs object
    this.setState({
      ...this.state.fields,
      fields: {
        ...this.state.fields,
        eventTime: moment,
      },
      meta: {
        ...this.state.meta,
        useEventTime: true,
      }
    })
  }

  _handleValidateField = (field, value, action) => {
    const moneyRegex = /^\d+\.\d{2}$/;
    const quantityRegex = /^\d+$/;
    // check action level
    if (action === ADD) {
      if (field === 'leadTime' && !quantityRegex.test(value)) {
        this.setState({
          fields: {
            ...this.state.fields,
            [field]: value === '' 
              ? ''
              : Number(value),
          },
          invalidFields: {
            ...this.state.invalidFields,
            [field]: true
          }
        })
      } else {
        const invalidFields = {...this.state.invalidFields};
        delete invalidFields[field];
        this.setState({
          fields: {
            ...this.state.fields,
            [field]: value,
          },
          invalidFields,
        })
      }
    }
    if (action === EDIT) {
      if (field === 'leadTime' && !quantityRegex.test(value)) {
        this.setState({
          fields: {
            ...this.state.fields,
            [field]: value === '' 
              ? ''
              : Number(value),
          },
          invalidFields: {
            ...this.state.invalidFields,
            [field]: true
          }
        })
      } else {
        const invalidFields = {...this.state.invalidFields};
        delete invalidFields[field];
        this.setState({
          fields: {
            ...this.state.fields,
            [field]: value,
          },
          invalidFields,
        })
      }
    }
    if (action === CHECKIN) {
      // check field level
      if (
        // deltaQuantity
        ( field === 'deltaQuantity' && !quantityRegex.test(value) )
        // itemUnitValue
        || ( field === 'itemUnitValue' && !moneyRegex.test(value) )
      ) {
        this.setState({
          fields: {
            ...this.state.fields,
            [field]: value === '' 
              ? ''
              : Number(value),
          },
          invalidFields: {
            ...this.state.invalidFields,
            [field]: true
          }
        })
      } else {
        const invalidFields = {...this.state.invalidFields}
        delete invalidFields[field]
        this.setState({
          fields: {
            ...this.state.fields,
            [field]: value,
          },
          invalidFields,
        })
      }
    }
    if (action === CHECKOUT) {
      if (field === 'deltaQuantity') {
        if (!quantityRegex.test(value) || value > this.state.fields.previousQuantity) {
          this.setState({
            fields: {
              ...this.state.fields,
              [field]: value === '' 
                ? ''
                : Number(value),
            },
            invalidFields: {
              ...this.state.invalidFields,
              [field]: true,
            }
          })
        } else {
          const invalidFields = {...this.state.invalidFields}
          delete invalidFields[field]
          this.setState({
            fields: {
              ...this.state.fields,
              [field]: value,
            },
            invalidFields,
          })
        }
      }
    }
  }

  _handleSubmit = e => {
    e.preventDefault();
    if (Object.keys(this.state.invalidFields).length === 0) {
      handleFirebaseSubmit(this.state.fields, this.props, this.state.meta)
    }
    return
  }

  /**
   * Renderers
   */
  
  render() {
    let schema;
    switch(this.props.dataSource) {
      case CONSUMABLES:
        schema = CONSUMABLESSCHEMA(this.props.action);
        break;
      case CAPITALEQUIPMENT:
        schema = CAPEQUIPMENTSCHEMA(this.props.action);
        break;
      default:
        break;
    }
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.close}
          className='TableDialog'
          disableBackdropClick
        >
          <form
            onSubmit={this._handleSubmit}
            className='TableDialog-Form'
          >
            {
              schema.map(entity => {
                // text / number field render
                if (entity.field === 'TextField' && !entity.options) {
                  return <TextField
                    className='TableDialog-Form-Input'
                    key={entity.id}
                    label={entity.label}
                    id={entity.id}
                    disabled={entity.disabled.indexOf(this.props.action) !== -1}
                    type={entity.type ? entity.type : undefined}
                    InputProps={{
                      startAdornment: entity.startAdornment
                        ? <InputAdornment position="start">{entity.startAdornment}</InputAdornment>
                        : null,
                      endAdornment: entity.endAdornment
                        ? <InputAdornment>{entity.endAdornment}</InputAdornment>
                        : null,
                    }}
                    value={this.state.fields[entity.id]}
                    onChange={this._handleChange(entity.validationRequired)}
                    required={entity.required}
                    fullWidth
                    error={this.state.invalidFields[entity.id]}
                  />
                }

                //select render
                if (entity.field === 'TextField' && entity.options) {
                  return <TextField
                    className='TableDialog-Form-Input'
                    key={entity.id}
                    label={entity.label}
                    name={entity.id} // our select library uses a name instead of an id
                    disabled={entity.disabled.indexOf(this.props.action) !== -1}
                    type={entity.type ? entity.type : undefined}
                    value={this.state.fields[entity.id]}
                    onChange={this._handleChange(entity.validationRequired)}
                    required={entity.required}
                    fullWidth
                    select
                  >
                    {
                      entity.options.map(option => {
                      return <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>})
                    }
                  </TextField>
                }

                if (entity.field === 'DateField') {
                  return <div className='dateFieldPicker' key={entity.id}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDateTimePicker
                        className='TableDialog-Form-Input'
                        label={entity.label}
                        value={this.state.fields[entity.id]}
                        onChange={this._handleMomentChange}
                        ampm={false}
                        dateRangeIcon
                        format="YYYY/MM/DD HH:mm"
                        showTodayButton
                        fullWidth
                        onError={console.log}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                }

                //buttons render
                if (entity.field === 'submitButton') {
                  return <div className='TableDialog-Form-Buttons' key={`${entity.id}Buttons`}>
                    <Button
                      type='submit'
                      variant='contained'
                      color={this.props.action === 'delete' ? 'secondary' : 'primary'}
                      fullWidth
                      disabled={!!Object.keys(this.state.invalidFields).length}
                    >
                      {this.props.action}
                    </Button>
                    <Button
                      variant='contained'
                      color='secondary'
                      fullWidth
                      onClick={this.props.close}
                    >
                      Cancel
                    </Button>
                  </div>
                }
                return null
              })
            }
          </form>
        </Dialog>
        {process.env.REACT_APP_situation === 'TEST' ? <pre><code>{JSON.stringify(this.state, null, 4)}</code></pre> : null }
      </div>
    );
  }
}

export default TableDialog;
