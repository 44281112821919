import React from 'react';
import {
  NAVIGATIONSTRUCTURE,
  CAPITALEQUIPMENT,
  CONSUMABLES,
  // VIEWUPDATECONSUMABLES,
  // CHECKINFILAMENT,
  // CHECKINRESIN,
  // CHECKINPACKAGING,
  // CHECKINHARDWARE,
  // CHECKOUTFILAMENT,
  // CHECKOUTRESIN,
  // CHECKOUTPACKAGING,
  // CHECKOUTHARDWARE,
} from '../../constants/constants.navigation';
import { 
  ADMINEQUIPMENTCOLUMNS, 
  USEREQUIPMENTCOLUMNS, 
  ADMINCONSUMABLECOLUMNS,
  USERCONSUMABLECOLUMNS,
} from '../../constants/constants.table';

import Table from '../../pages/Table/Table';
import ButtonsPage from './ButtonsPage';
import DeadPage from '../../pages/DeadPage/DeadPage';

const Routes = props => {
  const navigationObject = NAVIGATIONSTRUCTURE[props.match.url];

  const _getPage = () => {
    const isRead = props.claims.inventoryRead
    switch(props.match.url) {
      case CAPITALEQUIPMENT:
        return <Table 
          isAdmin={props.claims.inventoryCapEquipAdmin}
          isRead={isRead}
          dataSource='capitalEquipment'
          adminTop={ADMINEQUIPMENTCOLUMNS}
          userTop={USEREQUIPMENTCOLUMNS}
          title='Capital Equipment'
        />
      case CONSUMABLES:
        return <Table
          isAdmin={props.claims.inventoryConsumAdmin}
          isRead={isRead}
          dataSource='consumables'
          adminTop={ADMINCONSUMABLECOLUMNS}
          userTop={USERCONSUMABLECOLUMNS}
          title='Consumables'
        />
      // case VIEWUPDATECONSUMABLES:
      // case CHECKINFILAMENT:
      // case CHECKINRESIN:
      // case CHECKINPACKAGING:
      // case CHECKINHARDWARE:
      // case CHECKOUTFILAMENT:
      // case CHECKOUTRESIN:
      // case CHECKOUTPACKAGING:
      // case CHECKOUTHARDWARE:
      default:
        return <DeadPage text={props.match.url} />;
    }
  }
  if (navigationObject) {
    return <ButtonsPage navigationObject={navigationObject} />
  } else {
    return _getPage();
  }
}

export default Routes;