import React from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, IconButton } from '@material-ui/core';
import { Add, Assignment, Reply } from '@material-ui/icons';
import fire from '../../scripts/firebase'; 
import auth from '../../scripts/auth';
import { ASC, DESC, ADD } from '../../constants/constants.table'

import './Table.scss';

import Header from '../../components/Header/Header'
import Dialog from './TableDialog/TableDialog'
import TableActions from './TableActions/TableActions'

const INITIAL_STATE = {
  DEBUGDATABASE: [], // for debugging
  data: [],
  selected: null,
  sortOrderBy: '',
  sortOrder: ASC,
  dialogAction: '',
  isDialogOpen: false,
};

class TablePage extends React.Component {
  /**
   * Initializers
   */
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentWillMount(){
    this._getDatabaseData();
  }

  /**
   * Getters / Setters
   */

  _getDatabaseData = async () => {
    if (this.props.isRead) {
      let data = [];
      const payload = await fire.database().ref(this.props.dataSource).once('value');
      let jackpot = payload.val();
      if (jackpot) {
        for (let obj in jackpot) {
          data.push({
            ...jackpot[obj],
            itemID: obj,
          });
        }
      }
      // default sort
      // data.sort((a, b) => (b['Acquisition Date'] < a['Acquisition Date'] ? -1 : 1));
      const DEBUGDATABASE = jackpot // for debugging
      this.setState({
        DEBUGDATABASE, // for debugging
        data,
      });
    }
  };

  _openDialog = () => this.setState({ isDialogOpen: true });

  /**
   * Handlers
   */

  _handleSortClick = prop => {
    const sortOrderBy = prop;
    const sortOrder = this.state.sortOrderBy === prop && this.state.sortOrder === ASC ? DESC : ASC;
    let data =
      sortOrder === DESC
        ? this.state.data.sort((a, b) => (b[sortOrderBy] < a[sortOrderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[sortOrderBy] < b[sortOrderBy] ? -1 : 1));
    this.setState({ sortOrder, sortOrderBy, data });
  };

  _handleActionClick = ( dialogAction, selected = null ) => this.setState({
    dialogAction, selected 
  }, this._openDialog());

  _formatTopRow = string => {
    switch(string) {
      case 'currentQuantity':
        return 'Quantity'
      default:
        return `${string[0].toUpperCase()}${string.substring(1)}`;
    }
  }

  _formatCell = (col, data, d) => {
    if (col === 'modified') return new Date(data).toLocaleString();
    if (col === 'actions') return <TableActions 
        dataSource={this.props.dataSource}
        handleClick={this._handleActionClick} 
        d={d}
        isAdmin={this.props.isAdmin}
      />;
    if (col === 'leadTime') return data ? `${data} days` : 'N/A';
    return data;
  };

  /**
   * Renderers
   */

  render() {
    const { sortOrderBy, sortOrder, data } = this.state;
    const { isAdmin, adminTop, userTop } = this.props;
    const TOPROW = isAdmin ? adminTop : userTop;
    return (
      <div className="TablePage">
        <Header /> 
        {/* DIALOG COMPONENT */}
        {this.state.isDialogOpen ? <Dialog 
          open={this.state.isDialogOpen} 
          close={() => this.setState({ isDialogOpen: false })} 
          action={this.state.dialogAction}
          selected={this.state.selected} 
          retrieve={this._getDatabaseData}
          user={auth.auth().currentUser.email}
          dataSource={this.props.dataSource}
          topRow={TOPROW}
        /> : null}
        {/* END DIALOG COMPONENT */}
        <div className='TablePage-Title'>
          {this.props.title}
        </div>
        <div className='TablePage-Buttons'>
          {
            isAdmin && <IconButton onClick={() => this._handleActionClick(ADD)} >
              <Add className='TablePage-Button' />
            </IconButton> 
          }
          <IconButton >
            <Assignment className='TablePage-Button' />
          </IconButton>
          <Link to='/'>
            <IconButton >
              <Reply className='TablePage-Button' />
            </IconButton>
          </Link>
        </div>
        <Table className='TablePage-Table'>
          <TableHead>
            <TableRow>
              {TOPROW.map(col => {
                return (
                  <TableCell className='TablePage-Table-Cell' key={col}>
                    <TableSortLabel
                      active={sortOrderBy === col}
                      direction={sortOrder}
                      onClick={() => this._handleSortClick(col)}
                    >
                      {this._formatTopRow(col)}
                    </TableSortLabel>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {
              data.map((d,i) => {
                return (
                  <TableRow key={i} >
                    {TOPROW.map(col => {
                      return (
                        <TableCell
                          key={String(i).concat(col)}
                          className='TablePage-Table-Cell'
                        >
                          <div>{this._formatCell(col, d[col], d)}</div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            }
          </TableBody>
        </Table>
        {process.env.REACT_APP_situation === 'TEST' ? <pre><code>{JSON.stringify(this.state.DEBUGDATABASE, null, 4)}</code></pre> : null}
      </div>
    )
  };
}; 

export default TablePage;