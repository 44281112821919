import firebase from 'firebase/app';
import 'firebase/database'

const LIVE = {
  apiKey: "AIzaSyB1PGV3QkpeytUqCF-qLb4AuIX6DUY7YA4",
  databaseURL: "https://prp-1106.firebaseio.com",
  projectId: "prp-1106",
  storageBucket: "prp-1106.appspot.com",
  messagingSenderId: "671066921636"
};

const TEST = {
  apiKey: "AIzaSyCd_7-QzW4Aoffh1BM81yfCN5k9ZoQs1UQ",
  databaseURL: "https://auxinv-v2.firebaseio.com",
  projectId: "auxinv-v2",
  storageBucket: "auxinv-v2.appspot.com",
  messagingSenderId: "414147621963",
}

let env;
switch(process.env.REACT_APP_situation) {
  case 'TEST':
    env = TEST;
    break;
  case 'LIVE':
    env = LIVE;
    break;
  default:
    env = TEST;
}

const fire = firebase.initializeApp(env);

export default fire;
