export const CAPITALEQUIPMENT = '/capitalEquipment';
export const CONSUMABLES = '/consumables'
export const VIEWUPDATECONSUMABLES = '/viewUpdateConsumables';
export const CHECKINFILAMENT = '/checkInFilament';
export const CHECKINRESIN = '/checkInResin';
export const CHECKINPACKAGING = '/checkInPackaging';
export const CHECKINHARDWARE = '/checkInHardware';
export const CHECKOUTFILAMENT = '/checkOutFilament';
export const CHECKOUTRESIN = '/checkOutResin';
export const CHECKOUTPACKAGING = '/checkOutPackaging';
export const CHECKOUTHARDWARE = '/checkOutHardware';

export const NAVIGATIONSTRUCTURE = {
  '/': {
    Text1: 'Consumables',
    Link1: CONSUMABLES,
    Text2: 'Capital Equipment',
    Link2: CAPITALEQUIPMENT,
    title: 'Home',
    prevPage: false,
  },
    // '/consumables': {
    //   Text1: 'Check In/Out Consumables',
    //   Link1: 'checkInOutConsumables',
    //   Text2: 'View/Update Consumables',
    //   Link2: 'VIEWUPDATECONSUMABLES',
    //   title: 'consumables',
    //   prevPage: '/'
    // },
    //   '/checkInOutConsumables': {
    //     Text1: 'Check In',
    //     Link1: 'checkInConsumables',
    //     Text2: 'Check Out',
    //     Link2: 'checkOutConsumables',
    //     title: 'Check In / Out',
    //     prevPage: '/consumables'
    //   },
    //     '/checkInConsumables': {
    //       Text1: 'Check In Filament',
    //       Link1: CHECKINFILAMENT,
    //       Text2: 'Check In Resin',
    //       Link2: CHECKINRESIN,
    //       Text3: 'Check In Packaging',
    //       Link3: CHECKINPACKAGING,
    //       Text4: 'Check In Hardware',
    //       Link4: CHECKINHARDWARE,
    //       title: 'Check In',
    //       prevPage: '/checkInOutConsumables'
    //     },
    //     '/checkOutConsumables': {
    //       Text1: 'Check Out Filament',
    //       Link1: CHECKOUTFILAMENT,
    //       Text2: 'Check Out Resin',
    //       Link2: CHECKOUTRESIN,
    //       Text3: 'Check Out Packaging',
    //       Link3: CHECKOUTPACKAGING,
    //       Text4: 'Check Out Hardware',
    //       Link4: CHECKOUTHARDWARE,
    //       title: 'Check Out',
    //       prevPage: '/checkInOutConsumables'
    //     },
};