export const ADMINEQUIPMENTCOLUMNS = ["itemName", "currentQuantity", 'attribute', 'leadTime', 'actions', "modified"];
export const USEREQUIPMENTCOLUMNS = ["itemName", "currentQuantity", 'attribute', 'leadTime', "modified"];

export const ADMINCONSUMABLECOLUMNS = ["itemName", "currentQuantity", 'attribute', 'leadTime', 'actions', 'modified', ];
export const USERCONSUMABLECOLUMNS = ["itemName", "currentQuantity", 'attribute', 'leadTime', 'actions', 'modified', ];

export const ADD = 'add';
export const EDIT = 'edit';
export const DELETE = 'delete';
export const CHECKIN = 'checkIn';
export const CHECKOUT = 'checkOut';

export const ASC = 'asc';
export const DESC = 'desc';

export const CAPITALEQUIPMENT = 'capitalEquipment';
export const CONSUMABLES = 'consumables';